<script setup lang="ts">
interface PropTypes {
  height?: string
  width?: string
  radius?: string
  type?: string
}
const props = withDefaults(defineProps<PropTypes>(), {
  height: '100px',
  width: '100%',
  radius: '4px',
  type: 'default' || 'circle'
})
const { height, width, radius, type } = toRefs(props)
const styles = computed(() => {
  return {
    width: width.value,
    height: height.value,
    borderRadius: radius.value
  }
})
</script>

<template>
  <div class="skeleton" :class="`skeleton-${type}`" :style="styles" />
</template>
